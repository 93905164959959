window.showNotificationOn = function(element, text) {
  element = $(element);
  const prevTooltip = element.attr('title');
  const prevDataToggle = element.data('toggle');

  element.attr('title', text);
  element.tooltip({trigger: 'manual'});
  element.tooltip('hide');
  element.data('original-title', text);
  element.tooltip('show');
  setTimeout(function() {
    element.tooltip('hide');
    element.attr('title', prevTooltip);
    element.data('original-title', prevTooltip);
    element.data('toggle', prevDataToggle);

  }, 750);
}
