import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['modal'];
  
  connect() {
    this.isModalOpened = false;
    this.offerId = null;
    this.csrfToken = document.querySelector('meta[name="csrf-token"]')
      .getAttribute('content');
    
    $(this.modalTarget).on('show.bs.modal', (e) => this.setOfferIdToModal(e));
    $(this.modalTarget).on('hide.bs.modal', (e) => {
      this.isModalOpened = false;
      this.offerId = null;
    });
    this.$okButton().on('click', (e) => this.unenroll(e));
  }
  
  $okButton() {
    return $(this.modalTarget).find('.offer-modal-unenroll');
  }
  
  setOfferIdToModal(e) {
    const button = $(e.relatedTarget);
    this.isModalOpened = true;
    this.offerId = button.data('offerId');
  }
  
  async unenroll(e) {
    try {
      if (!this.isModalOpened) return;
      
      const response = await fetch(`/referral_partners/offers/${this.offerId}/unenroll`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': this.csrfToken
        }
      });
      if (response.status === 200) {
        window.location.href = `/referral_partners/offers/${this.offerId}`;
      }
    } catch (error) {
      console.log(error);
    }
  }
}