import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['modal', 'form'];
  static values = {
    offerId: String,
    crmType: String
  }

  subjectClass = 'events';

  connect() {
    this.initSelect2();
    this.attachListeners();
    this.attachOnModalHideAndShow();
  }

  initSelect2() {
    this.$crmField().css('width', '100%').select2({
      dropdownParent: $(this.modalTarget)
    });
  }

  $crmObject() {
    return $(this.formTarget).find('select.crm-object');
  }

  $crmField() {
    return $(this.formTarget).find('select.crm-field');
  }

  $crmFieldValue() {
    return $(this.formTarget).find('.form-control.crm-field-value');
  }

  $eventName() {
    return $(this.formTarget).find('.form-control.event-name-advanced');
  }

  attachListeners() {
    const self = this;
    this.$crmObject().on('change', () => self.eventChanged());
  }

  attachOnModalHideAndShow() {
    const self = this;
    $(this.modalTarget).on('show.bs.modal', async function(event) {
      $(self.modalTarget).find('.visible-when-creating').show();
      $(self.modalTarget).find('.visible-when-editing').hide();
      self.setDefaultValue();
    });

    $(this.modalTarget).on('hide.bs.modal', function() {
      self.setDefaultValue();
    })
  }

  async eventChanged() {
    await this.fetchCRMProperties(this.$crmObject().val());
  }

  async fetchCRMProperties(crmObject) {
    try {
      // Fetch crm data from pipedrive
      const response = await fetch(`/${this.crmTypeValue}/fetch_crm_properties?type=${crmObject.toLowerCase()}`)
      const { properties } = await response.json();

      if (this.crmTypeValue === 'salesforce') {
        this.appendSalesforceFields(properties)
      } else {
        this.appendPipedriveOrHubspotFields(properties)
      }
    } catch (error) {
      console.error(error);
    }
  }

  validateForm() {
    let valid = true;
    if (!this.$crmObject().val()) {
      valid = false;
    }
    if (!this.$crmField().val()) {
      valid = false;
    }
    if (!this.$crmFieldValue().val()) {
      valid = false;
    }
    if (!this.$eventName().val()) {
      valid = false;
    }

    $(this.modalTarget).find('input[type=submit]').attr('disabled', !valid);
  }

  setDefaultValue() {
    this.$crmObject().val(null);
    this.$crmField().empty();
    this.$crmField().val(null);
    this.$crmFieldValue().val(null);
    this.$eventName().val(null);
  }

  appendSalesforceFields(properties) {
    const self = this;

    // Clear existing options from the dropdown
    self.$crmField().empty();

    // Populate the dropdown with keys
    properties.forEach((field) => {
      const option = document.createElement('option');
      option.text = field;
      option.value = field;
      self.$crmField().append(option);
    });
    self.initSelect2();
  }

  appendPipedriveOrHubspotFields(properties) {
    // Get keys from the properties
    const keys = properties.map(p => (this.crmTypeValue === 'hubspot' || this.crmTypeValue === 'pipedrive') ? p.name : p.key);

    // Sort the keys alphabetically
    const sortedKeys = keys.sort();

    const self = this;

    // Clear existing options from the dropdown
    self.$crmField().empty();

    // Populate the dropdown with keys
    sortedKeys.forEach((key) => {
      const option = document.createElement('option');

      if (self.crmTypeValue === 'pipedrive') {
        // Find the corresponding property object
        const property = properties.find(p => p.name === key);
        option.text = property.name;
        option.value = property.key;
      } else {
        // Use key for both text and value for other CRM types
        option.text = key;
        option.value = key;
      }

      self.$crmField().append(option);
    });

    self.initSelect2();
  }

}
