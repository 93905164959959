// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
require("copy_to_clipboard")
require("show_notification")
require("scroll_to_error")
require("tablesorter/dist/js/jquery.tablesorter.min")
require("tablesorter/dist/js/jquery.tablesorter.widgets.min")
require("tablesorter/dist/js/widgets/widget-pager.min")

require("chart.js")

import $ from 'jquery';
global.$ = $;
global.jQuery = $;

global.renderjson = require("renderjson")

import 'bootstrap/dist/js/bootstrap';
import select2 from 'select2';
import 'select2/dist/css/select2.css';
import LocalTime from 'local-time';
import ConversionList from '../conversion_list'
window.ConversionList = ConversionList;

window._ = require('lodash');
window.activate_tablesort_search = activate_tablesort_search;


$(function() {
  start_local_time();
  autofocus_forms();
  activate_tooltips();
  activate_select2();
  scroll_to_error();
  activate_tablesort();
  activate_tablesort_search();
  render_json();
});

function autofocus_forms() {
  $('form.focus-first input:visible:not([readonly]):enabled:first').select().focus();
}

function activate_tooltips() {
  $(function () {
    $('[data-toggle="tooltip"]').tooltip()
  })
}

function activate_select2() {
  $('select.select2').select2();
}

function start_local_time() {
  LocalTime.start()
}

function activate_tablesort() {
  $('table.tablesort').tablesorter({theme: 'bootstrap'});
}

function activate_tablesort_search() {
  $('.tablesearch-toggle').off('click').on('click', function() {
    var table = $(this).closest('table');
    table.toggleClass('hide_filters');
    requestAnimationFrame(function() {
      table.find("tr.tablesorter-filter-row td:first-child input").focus();
    });
  });
}

function render_json() {
  renderjson.set_show_to_level(0)
  .set_icons('+', '-')
  .set_sort_objects(true);
  const nodes = document.querySelectorAll('.renderjson');
  nodes.forEach((node) => {
    node.appendChild(renderjson(JSON.parse(node.getAttribute('data-json'))));
    node.removeAttribute('data-json');
    node.classList.remove('renderjson');
  });
}

import "controllers"

require("trix")
require("@rails/actiontext")