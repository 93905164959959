import { Controller } from "stimulus"

export default class extends Controller {
  fire_event(event) {
    const eventId = $(event.target).data('event-id');
    const eventName = $(event.target).data('event-name') || $('input[name=event_arbitrary_name]').val();

    let props = $(`input[name=event_${eventId}_params]`).val();
    if (props.length === 0) {
      props = '{}';
    }
    props = JSON.parse(props);
    let revenue = $(`input[name=event_${eventId}_revenue]`).val();
    props.revenue = revenue;
    Attribution.track(eventName, props);
    Toastify({text: 'Event fired'}).showToast()
  }
}
