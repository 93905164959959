import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'testButton', 'form' ]

  connect() {
    $(this.formTarget).on('input paste', 'input, select, textarea', () => {
      this.testButtonTarget.text = 'Save to test';
      this.testButtonTarget.disabled = true;
      this.testButtonTarget.classList.add('disabled');
    })
  }
}
