import RequirementsController from './requirements_controller'

export default class extends RequirementsController {
  static targets = ['modal', 'form', 'shareTypeToggler']

  subjectClass = 'payout_rules';
  shareType = 'fixed'

  customConnect() {
    const self = this;
    self.initCrmFieldSelect2();
    $(this.shareTypeTogglerTarget).on('change', function(){ self.toggleAddons() });
  }

  initCrmFieldSelect2() {
    this.$crmField().css('width', '100%').select2({
      dropdownParent: $(this.modalTarget)
    });
  }

  $crmField() {
    return $(this.formTarget).find('select.rule-crm-field')
  }

  $crmFieldContainer() {
    return $(this.formTarget).find('div.rule-crm-field-container');
  }

  async toggleAddons() {
    $('.addon-toggable').toggle();
    $('.input-payout-value').toggleClass('text-right');
    const toggler = $('#fixed-or-percent');
    
    if (toggler.prop('checked')) {
      this.shareType = "percentage" 
    } else {
      this.shareType = "fixed";
    }
    console.log(this.shareType);
    $('input#payout_rule_share_type').val(this.shareType);

    this.$crmField().empty();
    if (this.shareType === 'fixed') {
      this.$crmFieldContainer().hide();
    } else {
      if (this.crmTypeValue !== 'stripe') {
        this.$crmFieldContainer().show();

        await this.fetchData(this.$urlForCrm());
      }
    }
  }

  async fetchData(url) {
    try {
      // Fetch crm data from pipedrive
      const response = await fetch(url);
      const { properties } = await response.json();

      if (this.crmTypeValue === 'salesforce') {
        this.appendSalesforceFields(properties)
      } else {
        this.appendPipedriveOrHubspotFields(properties)
      }
    } catch (error) {
      console.error(error);
    }
  }

  appendSalesforceFields(properties) {
    const self = this;

    // Clear existing options from the dropdown
    self.$crmField().empty();

    // Populate the dropdown with keys
    properties.forEach((field) => {
      const option = document.createElement('option');
      option.text = field;
      option.value = field;
      self.$crmField().append(option);
    });
  }

  appendPipedriveOrHubspotFields(properties) {
    // Get keys from the properties
    const keys = properties.map(p => (this.crmTypeValue === 'hubspot' || this.crmTypeValue === 'pipedrive') ? p.name : p.key);

    // Sort the keys alphabetically
    const sortedKeys = keys.sort();

    const self = this;

    // Clear existing options from the dropdown
    self.$crmField().empty();

    // Populate the dropdown with keys
    sortedKeys.forEach((key) => {
      const option = document.createElement('option');
      option.text = key;
      option.value = key;
      self.$crmField().append(option);
    });
  }

  $urlForCrm() {
    if (this.crmTypeValue === 'salesforce') {
      var offerId = $('#new-requirement-modal').attr('data-advertisers--requirements-offer-id-value')
      var selectedEvent = $("#payout_rule_event_name").val()
      var url = `/${this.crmTypeValue}/fetch_crm_numeric_properties?offer=${offerId}&event=${selectedEvent}`
    } else {
      var url = `/${this.crmTypeValue}/fetch_crm_properties?type=deal` // probably a bug as it always fetches properties from deal
    }
    return url;
  }
}
