export default class ConversionList {
  constructor(opts) {
    this.submitButton = opts.submitButton;
    this.ids = new Set();
    this.totalIds = opts.totalIds;
  }

  start() {
    this.updateButton();

    const self = this;
    $('input.cb_conv').change(function() { self.checkboxChanged($(this)) });
    $('input#cb-select-all').change(function() { self.selectAll($(this)) });
    $('#btn-select-total').click(function(){ self.selectTotalEntries() });
  }

  checkboxChanged(cb) {
    if (cb.prop('checked')) {
      this.addToCart(cb.data('id'));
    } else {
      this.removeFromCart(cb.data('id'));
    }
    this.updateButton();
  }

  addToCart(id) {
    this.ids.add(id);
  }

  removeFromCart(id) {
    this.ids.delete(id);
  }

  updateButton() {
    const conversionCount = this.ids.size;
    this.submitButton.each(function(){
      const verb = $(this).val().split(' ')[0];
      $(this).val(`${verb} ${conversionCount} conversions`);
    })
    if (this.ids.size > 0) {
      this.submitButton.removeAttr('disabled').removeClass('disabled');
    } else {
      this.submitButton.attr('disabled', true).addClass('disabled');
    }
    $('input#ids').val(JSON.stringify(
      Array.from(this.ids)
    ));
  }

  selectAll(masterCheckbox) {
    const masterCheckboxChecked = masterCheckbox.prop('checked');
    $('input.cb_conv').each(function() {
      $(this).prop('checked', !masterCheckboxChecked).trigger('click');
    });
    $('#btn-select-total').show();
  }

  selectTotalEntries() {
    $('.cb_conv,#cb-select-all').hide();
    this.ids = this.totalIds;
    this.updateButton();
  }
}

