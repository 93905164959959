// Usage:
// <div data-controller="common">
//   <button data-action="click->common#inspect">
//   <div style="display: none" data-common-target="inspector">

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "inspector" ]

  inspect() {
    this.inspectorTarget.style.display = this.inspectorTarget.style.display === 'none'
      ? 'block'
      : 'none';
  }
}
