import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    'searchForm',
    'searchInput',
    'sortDropdown',
    'unenrollModal'
  ];
  
  connect() {
    this.sortKeys = [];
    $(this.searchFormTarget).on('submit', (e) => this.search(e));
    $(this.sortDropdownTarget).on('click', () => this.toggleDropdown());
    this.$dropdownContainer().on('click', (e) => this.sort(e));
  }
  
  $searchInputField() {
    return $(this.searchInputTarget).find('.form-control');
  }
  
  $dropdownContainer() {
    return $(this.sortDropdownTarget).find('#dropdown-sort');
  }
  
  toggleDropdown() {
    this.$dropdownContainer().toggle();
  }
  
  search(e) {
    e.preventDefault();
    const searchKey = this.$searchInputField().val()
    const params = new URLSearchParams(window.location.search);
    params.set('offer_search', searchKey);
    window.location.search = params.toString();
  }
  
  sort(e) {
    e.preventDefault();
    const sort_key = e.target.getAttribute('data-sort');
    const params = new URLSearchParams(window.location.search);
    params.set('sort', sort_key);
    window.location.search = params.toString();
  }
}