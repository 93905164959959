import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["link", "message"]

  copy(event) {
    event.preventDefault()
    const url = this.linkTarget.getAttribute("data-url")
    navigator.clipboard.writeText(url)
  }
}
