import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['modal', 'form', 'eventButton'];
  static values = {
    offerId: String,
    crmType: String
  }

  subjectClass = 'requirements';

  connect() {
    this.initSelect2();
    this.attachListeners();
    this.attachOnModalShow();
    this.customConnect && this.customConnect();
  }

  initSelect2() {
    this.$eventName().css('width', '100%').select2({
      dropdownParent: $(this.modalTarget)
    });
  }

  $eventName() {
    return $(this.formTarget).find('select.event-name');
  }

  attachListeners() {
    const self = this;
    this.$eventName().on('change', () => self.eventChanged());
  }

  eventChanged() {
    if (this.$eventName().val() === 'Stripe Charge Succeeded') {
      $('.events_per_buyer_container').show();
    } else {
      $('.events_per_buyer_container').hide();
    }

    this.validateForm(this);
  }

  attachOnModalShow() {
    const self = this;
    $(this.modalTarget).on('show.bs.modal', function(event) {
      if (self.crmTypeValue === 'stripe') {
        $(self.modalTarget).find('.event-button').hide();
      } else {
        $(self.modalTarget).find('.event-button').show();
      }

      let button = $(event.relatedTarget);
      let subjectId = button.data('subjectId');

      if (subjectId) {
        self.loadSubject(subjectId);
        $(self.modalTarget).find('.visible-when-adding').hide();
        $(self.modalTarget).find('.visible-when-editing').show();
      } else {
        $(self.modalTarget).find('.visible-when-adding').show();
        $(self.modalTarget).find('.visible-when-editing').hide();
        $(self.formTarget).attr('action', `/companies/offers/${self.offerIdValue}/${self.subjectClass}`);
        $(self.formTarget).attr('method', 'POST');
        self.setDefaultControlValues();
      }
    });
  }

  validateForm() {
    let valid = true;
    if (this.$eventName().val()) {
      valid = true;
    } else {
      valid = false;
    }
    $(this.modalTarget).find('input[type=submit]').attr('disabled', !valid);
  }

  loadSubject(id) {
    const subject = window['SUBJECTS'][this.subjectClass][id];
    // Change DELETE URL
    $(this.formTarget).find('.btn.delete-subject').attr('href', `/companies/offers/${this.offerIdValue}/${this.subjectClass}/${id}`);

    // Change form submit URL
    const form = $(this.formTarget);
    form.append('<input type="hidden" name="_method" value="patch">')
    form.attr('action', `/companies/offers/${this.offerIdValue}/${this.subjectClass}/${id}`);


    // Set values
    const event_name = subject.event_name ?? '*';
    this.$eventName().val(event_name).trigger('change');

    this.setCustomValues(subject);

    $("option[value='" + event_name.replace(/'/g,"\\'") + "']").removeAttr("disabled");
  }

  setCustomValues(subject) {
    $('#cookie_window_days').val(subject.cookie_window_days);
    $('#payout_rule_events_per_buyer').val(subject.events_per_buyer);
  }

  setDefaultControlValues() {
    $('input[name=_method]').remove();

    this.$eventName().val(null).trigger('change', null);
    this.setCustomDefaultValues();
  }

  setCustomDefaultValues() {
    $('#cookie_window_days').val(null);
  }
}
